<template>
	<v-sheet v-if="tbody.length > 0" :class="{ 'internal-table-listing': internal }">
		<template v-if="!pageLoading">
			<v-simple-table class="bt-table">
				<template v-slot:default>
					<thead>
						<tr>
							<th v-for="(th, key) in sortedThead" :key="key" :width="th.width">
								<v-layout>
									<v-flex class="text-left">
										<template v-if="th.checkbox">
											<v-checkbox
												dense
												v-model="checkbox"
												hide-details
												class="m-0 p-0"
												v-on:change="selectAll"
												:indeterminate="indeterminate"
											></v-checkbox>
										</template>
										<template v-else>
											{{ th.name }}
										</template>
									</v-flex>
									<v-flex v-if="!th.checkbox && th.sortable" class="text-right ml-2">
										<template v-if="th.sort == 'ASC'"
											><v-icon small link>mdi-sort-ascending</v-icon></template
										>
										<template v-if="th.sort == 'DESC'"
											><v-icon small link>mdi-sort-descending</v-icon></template
										>
									</v-flex>
								</v-layout>
							</th>
						</tr>
					</thead>
					<tbody>
						<template v-for="(td, bkey) in tbody">
							<v-hover :key="`row-hover-${bkey}`" v-slot="{ hover }">
								<tr :key="`row-${bkey}`">
									<template v-for="(th, hkey) in sortedThead">
										<td
											:key="`first-${hkey}-${bkey}`"
											:width="th.width"
											v-on:click="routeToDetail(th.order, td.uuid)"
											:class="{ 'td-link': th.order > 1 }"
										>
											<template v-if="th.checkbox">
												<v-checkbox
													dense
													v-model="selectedRows"
													:value="td.uuid"
													hide-details
													class="m-0 p-0"
													multiple
													v-on:change="updateSelected()"
												></v-checkbox>
											</template>
											<template v-else-if="th.key == 'action'">
												<template
													v-if="
														!disableEdit &&
														getPermission(`${type}:update`) &&
														type != 'checkout' &&
														type != 'reservation'
													"
												>
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																depressed
																fab
																:dark="!is_edit_disabled(td)"
																:disabled="is_edit_disabled(td)"
																x-small
																color="green"
																v-bind="attrs"
																v-on="on"
																class="mr-3"
																v-on:click.stop.prevent="routeToUpdate(td.uuid)"
															>
																<v-icon>mdi-pencil</v-icon>
															</v-btn>
														</template>
														<span>Edit {{ type }}</span>
													</v-tooltip>
												</template>
												<template v-if="getPermission(`${type}:delete`) && type != 'property'">
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																depressed
																fab
																:dark="!is_delete_disabled(td)"
																x-small
																:disabled="is_delete_disabled(td)"
																color="red"
																v-bind="attrs"
																v-on="on"
																v-on:click.stop.prevent="deleteConfirm(td)"
															>
																<v-icon>mdi-delete</v-icon>
															</v-btn>
														</template>
														<span>Delete {{ type }}</span>
													</v-tooltip>
												</template>

												<v-chip
													color="blue"
													v-if="type == 'checkout' && is_checkin(td)"
													v-on:click.stop.prevent="createCheckInOut(td)"
													class="ml-3"
													text-color="white"
													label
												>
													Receive
												</v-chip>

												<v-tooltip top content-class="custom-top-tooltip" v-if="type == 'reservation' && false">
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															fab
															dark
															x-small
															color="blue"
															v-bind="attrs"
															class="ml-3"
															v-on="on"
															v-on:click.stop.prevent="createCheckInOut(td)"
														>
															<v-icon>mdi-share-outline mdi-flip-r</v-icon>
														</v-btn>
													</template>
													<span>Issue</span>
												</v-tooltip>
											</template>
											<template v-else-if="th.key == 'reservation_dates'">
												<p class="m-0 custom-nowrap-ellipsis" v-if="td.reserve_from_formatted">
													<b>From: </b> {{ td.reserve_from_formatted }}
												</p>
												<p class="m-0 custom-nowrap-ellipsis" v-if="td.reserve_to_formatted">
													<b>To: </b> {{ td.reserve_to_formatted }}
												</p>
											</template>
											<template v-else-if="th.key == 'reservation_type'">
												<Chip :text="td.type" :color="td.type == 'Asset' ? 'orange' : 'blue'"></Chip>
											</template>
											<template v-else-if="th.key == 'retired_type'">
												<Chip
													:text="td.retired_type"
													:color="td.retired_type == 'Asset' ? 'orange' : 'blue'"
												></Chip>
											</template>
											<template v-else-if="th.key == 'image'">
												<ImageTemplate circle style="max-width: 50px" :src="td.image"></ImageTemplate>
											</template>
											<template v-else-if="th.key == 'type_formatted'">
												<Chip
													v-for="(row, index) in td.type_formatted"
													:key="index"
													:text="row.text"
													:color="row.color"
													class="mr-2"
												></Chip>
											</template>
											<template v-else-if="th.key == 'related_formatted'">
												<Chip
													v-for="(row, index) in td.related_formatted"
													:key="index"
													:text="row.text"
													:color="row.color"
													class="mr-2"
												></Chip>
											</template>
											<template v-else-if="th.key == 'barcode'">
												<Chip :text="td.barcode" color="cyan"></Chip>
											</template>
											<template v-else-if="th.key == 'member_barcode'">
												<Chip :text="td.member_barcode" color="cyan"></Chip>
											</template>
											<template v-else-if="th.key == 'status'">
												<v-col md-12>
													<Status :status="td.status_value"></Status>
												</v-col>
												<template v-if="type == 'incharge-officer' && td.is_resigned == 1">
													<v-col md-12>
														<Chip text="Resigned" color="orange"></Chip>
													</v-col>
												</template>
												<template v-if="type == 'checkout' || type == 'checkin'">
													<v-col md-12>
														<template v-if="td.is_signed">
															<Chip text="Signed" color="green"></Chip>
														</template>
														<template v-else>
															<Chip text="Not Signed" color="orange"></Chip>
														</template>
													</v-col>
												</template>
											</template>

											<template v-else-if="th.key == 'type'">
												<template v-if="td.type == 'Issue'">
													<v-col md-12>
														<Chip text="Issue" color="red"></Chip>
													</v-col>
												</template>
												<template v-if="td.type == 'Receive'">
													<v-col md-12>
														<Chip text="Receive" color="orange"></Chip>
													</v-col>
												</template>
												<template v-if="td.type == 'Retired'">
													<v-col md-12>
														<Chip text="Retired" color="green"></Chip>
													</v-col>
												</template>
											</template>

											<template v-else-if="th.key == 'status_value'">
												<template v-if="td.status_value == 'active'">
													<v-col md-12>
														<Chip text="Active" color="green"></Chip>
													</v-col>
												</template>
												<template v-if="td.status_value == 'inactive'">
													<v-col md-12>
														<Chip text="Inactive" color="orange"></Chip>
													</v-col>
												</template>
												<template v-if="td.status_value == 'retired'">
													<v-col md-12>
														<Chip text="Retired" color="red"></Chip>
													</v-col>
												</template>
											</template>

											<template v-else-if="th.key == 'order_receive_pdf'">
												<v-icon
													v-if="td.has_checkin_pdf"
													v-on:click.stop.prevent="downloadPdf(td, 'order-receive-pdf')"
													class="red--text"
													large
													>mdi-file-pdf-box</v-icon
												>
												<span v-else class="text--secondary">-</span>
											</template>
											<template v-else-if="th.key == 'order_issue_pdf'">
												<v-icon
													v-if="td.has_checkout_pdf"
													v-on:click.stop.prevent="downloadPdf(td, 'order-issue-pdf')"
													class="red--text"
													large
													>mdi-file-pdf-box</v-icon
												>
												<span v-else class="text--secondary">-</span>
											</template>
											<template v-else-if="th.key == 'order_retire_pdf'">
												<v-icon
													v-if="td.has_retire_pdf"
													v-on:click.stop.prevent="downloadPdf(td, 'order-retire-pdf')"
													class="red--text"
													large
													>mdi-file-pdf-box</v-icon
												>
												<span v-else class="text--secondary">-</span>
											</template>
											<template v-else-if="th.key == 'file'">
												<v-btn color="blue darken-4 text-white" icon v-on:click.stop.prevent="downloadFile(td)">
													<v-icon>mdi-download</v-icon>
												</v-btn>
											</template>
											<template v-else-if="th.key == 'created_at'">
												<TableDateTime
													v-if="td.created_at"
													:human-format="td.created_at"
													:system-format="td.added_at"
												></TableDateTime>
											</template>
											<template v-else-if="th.key == 'modified_at'">
												<TableDateTime
													v-if="td.modified_at"
													:human-format="td.modified_at"
													:system-format="td.updated_at"
												></TableDateTime>
												<span v-else>-</span>
											</template>
											<template v-else-if="th.key == 'retired_attachment'">
												<template v-if="td.retired_attachment && td.retired_attachment.length">
													<v-chip
														v-for="(row, index) in td.retired_attachment"
														:key="index"
														v-on:click.stop.prevent="downloadFile(row)"
														class="mr-2"
														>{{ row.name }}</v-chip
													>
												</template>
												<ShowValue v-else :object="td" object-key="nothing-found" :label="th.name"></ShowValue>
											</template>
											<template v-else>
												<ShowValue :object="td" :object-key="th.key" :label="th.name"></ShowValue>
											</template>
										</td>
									</template>
								</tr>
							</v-hover>
						</template>
					</tbody>
				</template>
			</v-simple-table>
			<v-row class="my-2">
				<v-col md="6" class="my-auto">
					<p class="m-0">{{ showingString }}</p>
				</v-col>
				<v-col md="6" class="text-right">
					<v-pagination
						color="blue darken-4"
						v-model="page"
						:length="totalPage"
						total-visible="9"
						v-on:input="updatePagination($event)"
					></v-pagination>
				</v-col>
			</v-row>
		</template>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
		<Dialog :dialog="deleteDialog" :dialog-width="640">
			<template v-slot:title> Delete {{ type }}</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">
							<span class="text-capitalize">{{ type }} </span>
							<span class="red--text font-bolder lighten-1">#{{ deleteText }}</span>
							will be deleted forever and cannot be retrieved later. Are you sure about deleting it?
						</p>
					</v-col>
					<v-col v-if="deleteNote" md="12" class="pb-0 mt-2 my-auto">
						<em>Note: {{ deleteNote }}</em>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="deleteButton"
					:disabled="deleteButton"
					depressed
					color="red lighten-1"
					tile
					v-on:click="deleteRow"
				>
					Yes! Delete
				</v-btn>
				<v-btn depressed tile :disabled="deleteButton" v-on:click="deleteClear"> No, Close </v-btn>
			</template>
		</Dialog>
	</v-sheet>
	<v-sheet v-else>
		<p class="m-0 row-not-found text-center">
			<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
			Uhh... There are no {{ type }} at the moment.
		</p>
	</v-sheet>
</template>
<script>
import { toSafeInteger, isArray } from "lodash";
import { mapGetters } from "vuex";
import { SET_SELECTED_ROWS, SET_CURRENT_PAGE } from "@/core/services/store/listing.module";
import Chip from "@/view/components/Chip";
import Status from "@/view/components/Status";
import Dialog from "@/view/components/Dialog";
import TableDateTime from "@/view/components/TableDateTime";
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import ImageTemplate from "@/view/components/Image";
import { CART_COUNT } from "@/core/services/store/config.module";
import { getToken } from "@/core/services/jwt.service.js";

export default {
	name: "table-template",
	props: {
		type: {
			type: String,
			default: null,
		},
		deleteEndpoint: {
			type: String,
			default: null,
		},
		detailRoute: {
			type: String,
			default: null,
		},
		updateRoute: {
			type: String,
			default: null,
		},
		deleteNote: {
			type: String,
			default: null,
		},
		dialogUpdate: {
			type: Boolean,
			default: false,
		},
		disableEdit: {
			type: Boolean,
			default: false,
		},
		dialogView: {
			type: Boolean,
			default: false,
		},
		internal: {
			type: Boolean,
			default: false,
		},
		pageLoading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			checkbox: false,
			indeterminate: false,
			selectedRows: [],
			deleteDialog: false,
			deleteButton: false,
			deleteUUID: null,
			deleteURL: null,
			deleteText: null,
			page: 1,
		};
	},
	watch: {
		currentPage(param) {
			this.page = param;
		},
		selected(param) {
			if (param.length < 1) {
				this.checkbox = false;
				this.indeterminate = false;
				this.selectedRows = [];
			}
		},
	},
	methods: {
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.$emit("reload:content", true);
			});
		},
		downloadFile(row) {
			window.open(row.url, "_blank");
		},
		downloadPdf(param, type) {
			if (type == "order-receive-pdf") {
				if (!param.has_checkin_pdf) {
					return false;
				}
			}
			if (type == "order-issue-pdf") {
				if (!param.has_checkout_pdf) {
					return false;
				}
			}
			if (type == "order-retire-pdf") {
				if (!param.has_retire_pdf) {
					return false;
				}
			}

			const token = getToken();
			const url = `${this.$apiURL()}${type}/${param.id}/download?token=${token}`;
			window.open(url, "_blank");
		},
		routeToDetail(order, uuid) {
			if (order < 2) {
				return false;
			}
			if (!this.dialogView) {
				if (this.detailRoute) {
					if (this.internal) {
						let routeData = this.$router.resolve({
							name: this.detailRoute,
							params: { uuid },
							query: { t: new Date().getTime() },
						});
						window.open(routeData.href, "_blank");
					} else {
						this.$router.push({
							name: this.detailRoute,
							params: { uuid },
							query: { t: new Date().getTime() },
						});
					}
				}
			} else {
				this.$emit("view:dialog", uuid);
				return false;
			}
		},
		routeToUpdate(uuid) {
			if (this.dialogUpdate) {
				this.$emit("update:dialog", uuid);
				return false;
			}
			this.$router.push({
				name: this.updateRoute,
				params: { uuid },
				query: { t: new Date().getTime() },
			});
		},
		deleteClear() {
			this.deleteDialog = false;
			this.deleteUUID = null;
			this.deleteURL = null;
			this.deleteText = null;
		},
		deleteRow() {
			this.deleteButton = true;
			ApiService.delete(this.deleteURL)
				.then(() => {
					this.$emit("reload:content");
					this.$store.dispatch(CART_COUNT);
					this.deleteClear();
				})
				.catch(({ response }) => {
					console.log({ response });
				})
				.finally(() => {
					this.deleteButton = false;
				});
		},
		deleteConfirm({ uuid, barcode }) {
			this.deleteDialog = true;
			this.deleteUUID = uuid;
			this.deleteURL = this.deleteEndpoint + uuid;
			this.deleteText = barcode;
		},
		getValue(td, key) {
			const key_array = key.split(".");
			if (key_array.length > 1) {
				if (key_array[3]) {
					return td[key_array[0]][key_array[1]][key_array[2]][key_array[3]];
				}
				if (key_array[2]) {
					return td[key_array[0]][key_array[1]][key_array[2]];
				}
				if (key_array[1]) {
					return td[key_array[0]][key_array[1]];
				}
				if (key_array[0]) {
					return td[key_array[0]];
				}
			}
			return td[key] || "-";
		},
		updateSelected() {
			if (this.selectedRows && isArray(this.selectedRows)) {
				const selectedRowsLength = toSafeInteger(this.selectedRows.length);
				const tbodyLength = toSafeInteger(this.tbody.length);
				if (selectedRowsLength > 0) {
					if (!this.checkbox) {
						this.checkbox = true;
					}
					this.indeterminate = false;
					if (selectedRowsLength != tbodyLength) {
						this.indeterminate = true;
					}
				} else {
					this.selectedRows = [];
					this.checkbox = false;
					this.indeterminate = false;
				}
				this.$store.commit(SET_SELECTED_ROWS, this.selectedRows);
			} else {
				this.$store.commit(SET_SELECTED_ROWS, []);
			}
		},
		selectAll() {
			this.selectedRows = [];
			if (this.checkbox) {
				for (let i = 0; i < this.tbody.length; i++) {
					this.selectedRows.push(this.tbody[i].uuid);
				}
			}
			this.$store.commit(SET_SELECTED_ROWS, this.selectedRows);
		},
		createCheckInOut(data) {
			this.$emit("createCheckInOut", data);
		},
		is_edit_disabled(row) {
			if (this.type == "service") {
				if (row.status == 2) {
					return true;
				}
			}
			if (this.type == "incharge-officer") {
				if (row.is_resigned == 1) {
					return true;
				}
			}
			return false;
		},
		is_checkin(row) {
			if (this.type == "checkout") {
				if (row.inventory > 0) {
					return false;
				}
			}
			return true;
		},
		is_delete_disabled(row) {
			if (this.type == "service") {
				if (row.status == 2) {
					return true;
				}
			}
			return false;
		},
	},
	components: {
		Chip,
		Status,
		Dialog,
		ShowValue,
		ImageTemplate,
		TableDateTime,
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"currentPage",
			"totalPage",
			"showingString",
			"thead",
			"sortedThead",
		]),
	},
};
</script>
