<template>
	<v-sheet class="group" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="2" class="py-0 my-auto">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="true"
					:items="listingStatusArr"
					label="Status"
					solo
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" :text-color="item.textcolor">
								{{ item.document_status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select>
			</v-col>
			<v-col md="10" class="py-2 my-auto text-right listing-right">
				<template>
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('group')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('group')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</template>
			</v-col>
		</v-row>
		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Related #, Name, Incharge Officer"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table
			:page-loading="pageLoading"
			type="retired"
			delete-endpoint="checkin/"
			v-on:reload:content="filterRows"
		></Table>
		<template v-if="selectRequeseteddialog">
			<SelectRequested
				v-on:selectRelatedTo="selectRelatedTo"
				v-on:close="selectRequeseteddialog = false"
				:checkout-dialog="selectRequeseteddialog"
			></SelectRequested>
		</template>
		<template v-if="checkinDialog">
			<CheckinTemplate
				:type="relatedTypeCheckIn"
				:endpoint="relatedEndPointCheckIn"
				:type-uuid="relatedUuidCheckIn"
				:checkout-uuid="checkOutUuid"
				v-on:success="
					checkinDialog = false;
					checkOutUuid = null;
					relatedUuidCheckIn = null;
					relatedTypeCheckIn = null;
					relatedEndPointCheckIn = null;
					getListing();
				"
				v-on:close="checkinDialog = false"
				:checkin-dialog="checkinDialog"
			></CheckinTemplate>
		</template>
		<template v-if="exportCurrentDialog">
			<ExportDialog
				endpoint="asset/export?type=asset&current=1"
				title="Assets"
				current
				:export-dialog.sync="exportCurrentDialog"
				v-on:close="exportCurrentDialog = false"
			></ExportDialog>
		</template>
		<template v-if="exportDialog">
			<ExportDialog
				endpoint="asset/export?type=asset"
				title="Assets"
				:export-dialog.sync="exportDialog"
				v-on:close="exportDialog = false"
			></ExportDialog>
		</template>
		<template v-if="advanceSearch">
			<FilterDialog
				title="Assets"
				:filter-dialog.sync="advanceSearch"
				:btx-filter.sync="listingFilter"
				:btx-query.sync="btxQuery"
				v-on:do-search="doAdvanceSearch"
				v-on:close="advanceSearch = false"
			>
			</FilterDialog>
		</template>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import SelectRequested from "@/view/components/Select-Checkout-Requested";
import CheckinTemplate from "@/view/components/CheckinTemplate";

export default {
	name: "group-listing",
	title: "Listing Group",
	mixins: [ListingMixin],
	data() {
		return {
			uuid: null,
			dialog: false,
			checkinDialog: false,
			relatedUuid: null,
			status: "retire",
			listingStatusArr: [
				{
					id: 10,
					type: "retire",
					text: "All Retired",
					value: "retire",
					description: "List of All Retired",
					color: "blue",
					textcolor: "white",
					dbvalue: 99,
					status_count: 0,
					order: 0,
				},
			],
			relatedType: null,
			relatedEndPoint: null,
			checkOutUuid: null,
			relatedUuidCheckIn: null,
			relatedTypeCheckIn: null,
			relatedEndPointCheckIn: null,
			checkoutDialog: false,
			selectRequeseteddialog: false,
			pageTitle: "Retire",
			pageBreadcrumbs: [{ text: "Retire", disabled: true }],
			endpoint: "retire",
			defaultFilter: {},
		};
	},
	components: {
		SelectRequested,
		CheckinTemplate,
	},
	methods: {
		selectRelatedTo(data) {
			this.relatedUuid = data.id;
			this.relatedType = data.type;
			this.relatedEndPoint = data.endpoint;
			if (this.relatedUuid && this.relatedType && this.relatedEndPoint) {
				this.selectRequeseteddialog = false;
				this.checkoutDialog = true;
			}
		},
		createCheckin(data) {
			this.relatedUuidCheckIn = data.asset > 0 ? data.asset_uuid : data.asset_stock_uuid;
			this.relatedTypeCheckIn = data.asset > 0 ? "Asset" : "Asset Stock";
			this.relatedEndPointCheckIn = data.asset > 0 ? "asset" : "asset-stock";
			this.checkOutUuid = data.checkout_uuid;
			this.checkinDialog = true;
		},
	},
};
</script>
