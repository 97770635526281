<template>
	<v-layout>
		<v-flex md8>
			<DatePicker
				:hide-details="hideDetails"
				:readonly="readonly"
				:disabled="disabled"
				:loading="loading"
				:label="label"
				:id="id"
				:min-date="minDate"
				:placeholder="placeholder"
				v-model="date"
				v-on:change="emitValue()"
				:rules="[vrules.required(date, placeholder)]"
				:content-class="{
					required: !date,
				}"
				v-if="required"
			></DatePicker>
			<DatePicker
				v-else
				:hide-details="hideDetails"
				:readonly="readonly"
				:disabled="disabled"
				:loading="loading"
				:label="label"
				:id="id"
				:min-date="minDate"
				:placeholder="placeholder"
				v-model="date"
				v-on:change="emitValue()"
			></DatePicker>
		</v-flex>
		<v-flex md4>
			<TimePicker
				class="mt-3"
				:readonly="readonly"
				:disabled="disabled"
				:loading="loading"
				:label="label"
				:id="id"
				placeholder="Time"
				v-model="time"
				format="HH:mm"
				v-on:change="emitValue()"
				:class="{
					required: !time,
				}"
			></TimePicker>
		</v-flex>
	</v-layout>
</template>
<script>
import DatePicker from "@/view/components/DatePicker";
//import TimePicker from "@/view/components/TimePicker";
import TimePicker from "vue2-timepicker/src/vue-timepicker.vue";
import MomentJS from "moment-timezone";

MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "date-time-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: String,
			default: null,
		},
		minDate: {
			type: [Date, String],
			default: null,
		},
		maxDate: {
			type: [Date, String],
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		DatePicker,
		TimePicker,
	},
	watch: {
		value() {
			this.init();
		},
	},
	methods: {
		init() {
			console.log({value: this.value})
			if (!this.value) {
				return false;
			}
			const dateTime = this.value;
			this.date = MomentJS(dateTime).format("YYYY-MM-DD");
			this.time.HH = MomentJS(dateTime).format("HH");
			this.time.mm = MomentJS(dateTime).format("mm");
			this.emitValue();
		},
		emitValue() {
			if (!this.date) {
				return false;
			}

			const date = `${this.date} ${this.time.HH}:${this.time.mm}`;
			const formatted = MomentJS(date).format("YYYY-MM-DD HH:mm");

			this.$emit("input", formatted);
			this.$emit("change", formatted);
		},
	},
	data() {
		return {
			date: null,
			time: {
				HH: "00",
				mm: "00",
			},
		};
	},
	mounted() {
		this.init();
	},
};
</script>
