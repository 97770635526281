<template>
	<v-text-field
		hide-details
		outlined
		class="pt-0 mt-3"
		:placeholder="btxPlaceholder"
		v-mask="'##/##/####'"
		v-model="f_date"
		:rules="rules"
		:readonly="readonly"
		:disabled="disabled"
		:loading="loading"
		v-on:change="validate()"
	></v-text-field>
</template>

<script>
import MomentJS from "moment-timezone";

MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "date-picker",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: [Date, String],
			default: null,
		},
		hidePrefixIcon: {
			type: Boolean,
			default: false,
		},
		minDate: {
			type: [Date, String],
			default: null,
		},
		maxDate: {
			type: [Date, String],
			default: null,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		placeholder: {
			type: String,
			default: "Date",
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		contentClass: {
			type: Object,
			default: null,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			date_list: [],
			month_list: [],
			year_list: [],
			f_date: null,
			date: null,
			is_valid_date: false,
			datepicker: false,
			dateFormatted: null,
		};
	},
	watch: {
		f_date() {
			this.$nextTick(() => {
				this.emitValue();
			});
		},
		value() {
			this.initValue();
		},
	},
	methods: {
		initValue() {
			if (this.value && this.isValidSystemFormat(this.value)) {
				const v_date = MomentJS(this.value, "YYYY-MM-DD");
				if (v_date.isValid()) {
					this.f_date = v_date.format("DD/MM/YYYY");
					this.validate();
				} else {
					this.f_date = null;
					this.is_valid_date = false;
				}
			}
		},
		isValidSystemFormat(param) {
			if (!param) {
				return false;
			}
			const [year, month, day] = param.split("-");
			if (year && year.length == 4 && month && month.length == 2 && day && day.length == 2) {
				return true;
			}
			return false;
		},
		isValidScreenFormat(param) {
			if (!param) {
				return false;
			}
			const [day, month, year] = param.split("/");
			if (year && year.length == 4 && month && month.length == 2 && day && day.length == 2) {
				return true;
			}
			return false;
		},
		validate() {
			this.is_valid_date = false;
			if (this.isValidScreenFormat(this.f_date)) {
				const mdate = MomentJS(this.f_date, "DD/MM/YYYY");
				this.is_valid_date = mdate.isValid();
				this.$nextTick(() => {
					this.emitValue();
				});
			}
		},
		emitValue() {
			this.$nextTick(() => {
				if (this.isValidScreenFormat(this.f_date)) {
					let date = null;
					if (this.is_valid_date) {
						date = MomentJS(this.f_date, "DD/MM/YYYY").format("YYYY-MM-DD");
					}
					this.$emit("input", date);
					this.$emit("change", date);
				} else {
					this.$emit("input", null);
					this.$emit("change", null);
				}
			});
		},
	},
	computed: {
		btxPlaceholder() {
			return this.placeholder + " [DD/MM/YYYY]";
		},
	},
	mounted() {
		this.initValue();
		this.$nextTick(() => {
			this.emitValue();
		});
	},
};
</script>
